import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function Pedagogie() {
  return (
    <div className="p-5 mx-auto sm:p-10 md:p-16 bg-coolGray-100 text-gray-800">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 text-center">
        <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto text-center">
            <span class="relative inline-block text-blue-900">Notre programme pédagogique</span>
          </h2>
          {/* <p class="text-base text-gray-700 md:text-lg">
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
            rem aperiam, eaque ipsa quae.
          </p> */}
          <div class="flex mt-6 justify-center">
            <div class="w-16 h-1 rounded-full bg-blue-900 inline-flex"></div>
          </div>
        </div>
      </div>
      <div className="flex flex-col max-w-3xl mx-auto overflow-hidden rounded">
        <StaticImage
          src="../images/pedagogie.webp"
          alt=""
          className="w-full h-60 sm:h-96 bg-coolGray-500"
        />
        <div className="p-6 pb-12 m-4 mx-auto -mt-1 space-y-6 lg:max-w-2xl sm:px-10 sm:mx-12 lg:rounded-md bg-white shadow-lg z-10">
          <div className="text-coolGray-800">
            <ul className="list-disc space-y-2">
              <span className="font-medium text-blue-900">ORGANISATION DES ENSEIGNEMENTS AU COLLÈGE</span>
              <li>Le collège marque l’entrée des élèves dans l’enseignement secondaire.</li>
              <li>Il est organisé en quatre niveaux : 6ème, 5ème, 4ème et 3ème.</li>
              <li>
                La classe de 6ème occupe une place particulière : elle permet aux élèves de
                s’adapter au rythme, à l’organisation pédagogique et au cadre de vie du collège tout
                en poursuivant leurs apprentissages. Il leur faut intégrer les savoirs, les
                savoir-faire et les méthodes de travail personnel indispensables pour réussir leur
                scolarité au collège.
              </li>
              <li>
                Les élèves, de la 5ème, 4ème et 3ème sont eux amenés à conjuguer d’une part :<br />{' '}
                - une pensée personnelle en construction
                <br />- un développement de leurs talents propres
                <br />- leurs aspirations
                <br /> et d’autre part : <br />
                - l’ouverture aux autres <br />
                - la diversité
                <br />- la découverte tout en préparant la poursuite de leur formation et leur
                future participation active à l’évolution de la société..
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
