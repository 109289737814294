import React from 'react';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import AnimationRevealPage from '../../components/AnimationRevealPage';
import Pedagogie from '../../components/Pedagogie';

export default function ProgrammePedagogique() {
  return (
    <AnimationRevealPage>
      <Layout>
        <Seo
          title="Collègé Jules Verne - Programme Pédagogique"
          description="N'hésitez pas à nous contacter pour toutes questions ou demande"
        />
        <Pedagogie />
      </Layout>
    </AnimationRevealPage>
  );
}
